$prefix: 'phoenix-' !default;


//*-----------------------------------------------
//|   Color System
//-----------------------------------------------*/

$white: #fff !default;
$gray-50: #f5f7fa !default; //(245, 247, 250)
$gray-100: #eff2f6 !default; //(239, 242, 246)
$gray-200: #e3e6ed !default; //(227, 230, 237)
$gray-300: #cbd0dd !default; //(203, 208, 221)
$gray-400: #9fa6bc !default; //(159, 166, 188)
$gray-500: #8a94ad !default; //(138, 148, 173)
$gray-600: #6e7891 !default; //(110, 120, 145)
$gray-700: #525b75 !default; //(82, 91, 117)
$gray-800: #3e465b !default; //(62, 70, 91)
$gray-900: #31374a !default; //(49, 55, 74)
$gray-1000: #222834 !default; //(34, 40, 52)
$gray-1100: #141824 !default; //(20, 24, 36)
$black: #000000 !default;

$grays: (
  '50': $gray-50,
  '100': $gray-100,
  '200': $gray-200,
  '300': $gray-300,
  '400': $gray-400,
  '500': $gray-500,
  '600': $gray-600,
  '700': $gray-700,
  '800': $gray-800,
  '900': $gray-900,
  '1000': $gray-1000,
  '1100': $gray-1100
) !default;

//*-----------------------------------------------
//|   Solid Colors
//-----------------------------------------------*/

// scss-docs-start color-variables
$blue:    #3874ff !default;
$indigo:  #6610f2 !default;
$purple:  #6f42c1 !default;
$pink:    #d63384 !default;
$red:     #EC1F00 !default;
$orange:  #E5780B !default;
$yellow:  #ffc107 !default;
$green:   #25b003 !default;
$teal:    #20c997 !default;
$cyan:    #0097eb !default;
// scss-docs-end color-variables

// scss-docs-start colors-map
$colors: (
  "blue":       $blue,
  "indigo":     $indigo,
  "purple":     $purple,
  "pink":       $pink,
  "red":        $red,
  "orange":     $orange,
  "yellow":     $yellow,
  "green":      $green,
  "teal":       $teal,
  "cyan":       $cyan,
  "black":      $black,
  "white":      $white,
  "gray":       $gray-600,
  "gray-dark":  $gray-1100
) !default;
// scss-docs-end colors-map

// Min contrast ratio
$min-contrast-ratio: 2 !default;

// fusv-disable
$blue-50: #F5F8FF !default;
$blue-100: #E5EDFF !default;
$blue-200: #ADC5FF !default;
$blue-300: #85A9FF !default;
$blue-400: #6090FF !default;
$blue-500: #3874FF !default; // base-color
$blue-600: #004DFF !default; 
$blue-700: #003CC7 !default;
$blue-800: #0033AA !default;
$blue-900: #00267B !default;
$blue-1000: #00174D !default;

$red-50:  #FFEDEB !default;
$red-100: #FFE0DB !default;
$red-200: #FABCB3 !default;
$red-300: #F48270 !default;
$red-400: #FB624A !default;
$red-500: #FA3B1D !default; // base-color
$red-600: #CC1B00 !default;
$red-700: #B81800 !default;
$red-800: #901400 !default;
$red-900: #630D00 !default;
$red-1000: #380700 !default;

$green-50: #F0FDEC !default;
$green-100: #D9FBD0 !default;
$green-200: #BEE8B4 !default;
$green-300: #90D67F !default;
$green-400: #51C035 !default;
$green-500: #25B003 !default; // base-color
$green-600: #23890B !default;
$green-700: #1C6C09 !default;
$green-800: #115A00 !default;
$green-900: #0B3D00 !default;
$green-1000: #061F00 !default;

$orange-50: #FFF6E0 !default;
$orange-100: #FFEFCA !default;
$orange-200: #FFE6AD !default;
$orange-300: #FFCC85 !default;
$orange-400: #EA9C3C !default;
$orange-500: #E5780B !default; // base-color
$orange-600: #D6630A !default;
$orange-700: #BC3803 !default;
$orange-800: #901400 !default;
$orange-900: #630D00 !default;
$orange-1000: #380700 !default;

$cyan-50: #F0FAFF !default;
$cyan-100: #C7EBFF !default;
$cyan-200: #96D9FF !default;
$cyan-300: #60C6FF !default;
$cyan-400: #33ACEF !default;
$cyan-500: #0097EB !default; // base-color
$cyan-600: #0080C7 !default;
$cyan-700: #005585 !default;
$cyan-800: #004870 !default;
$cyan-900: #003A5B !default;
$cyan-1000: #002337 !default;

$blues: (
  'blue-50': $blue-50,
  'blue-100': $blue-100,
  'blue-200': $blue-200,
  'blue-300': $blue-300,
  'blue-400': $blue-400,
  'blue-500': $blue-500,
  'blue-600': $blue-600,
  'blue-700': $blue-700,
  'blue-800': $blue-800,
  'blue-900': $blue-900,
  'blue-1000': $blue-1000,
) !default;

$reds: (
  'red-50': $red-50,
  'red-100': $red-100,
  'red-200': $red-200,
  'red-300': $red-300,
  'red-400': $red-400,
  'red-500': $red-500,
  'red-600': $red-600,
  'red-700': $red-700,
  'red-800': $red-800,
  'red-900': $red-900,
  'red-1000': $red-1000,
) !default;

$greens: (
  'green-50': $green-50,
  'green-100': $green-100,
  'green-200': $green-200,
  'green-300': $green-300,
  'green-400': $green-400,
  'green-500': $green-500,
  'green-600': $green-600,
  'green-700': $green-700,
  'green-800': $green-800,
  'green-900': $green-900,
  'green-1000': $green-1000,
) !default;

$oranges: (
  'orange-50': $orange-50,
  'orange-100': $orange-100,
  'orange-200': $orange-200,
  'orange-300': $orange-300,
  'orange-400': $orange-400,
  'orange-500': $orange-500,
  'orange-600': $orange-600,
  'orange-700': $orange-700,
  'orange-800': $orange-800,
  'orange-900': $orange-900,
  'orange-1000': $orange-1000,
) !default;

$cyans: (
  'cyan-50': $cyan-50,
  'cyan-100': $cyan-100,
  'cyan-200': $cyan-200,
  'cyan-300': $cyan-300,
  'cyan-400': $cyan-400,
  'cyan-500': $cyan-500,
  'cyan-600': $cyan-600,
  'cyan-700': $cyan-700,
  'cyan-800': $cyan-800,
  'cyan-900': $cyan-900,
  'cyan-1000': $cyan-1000,
) !default;

//fusv-enable

//*-----------------------------------------------
//|   Theme Colors
//-----------------------------------------------*/
$primary: $blue !default;
$secondary: $gray-900 !default;
$success: $green !default;
$info: $cyan !default;
$warning: $orange !default;
$danger: $red !default;
$light: $gray-100 !default;
$dark: $gray-1100 !default;

$theme-colors: (
  'primary': $primary,
  'secondary': $secondary,
  'success': $success,
  'info': $info,
  'warning': $warning,
  'danger': $danger,
  'light': $light,
  'dark': $dark
) !default;


//*-----------------------------------------------
//|   Body
//-----------------------------------------------*/
$body-color: $gray-900 !default;
$body-bg: $gray-50 !default;

$body-secondary-color: $gray-800 !default;
$body-secondary-bg: $gray-200 !default;

$body-tertiary-color: $gray-700 !default;
$body-tertiary-bg: $gray-300 !default;

$body-quaternary-color: $gray-500 !default;
$body-quaternary-bg: $gray-400 !default;

$body-emphasis-color: $gray-1100 !default;
$body-emphasis-bg: $white !default;

$body-highlight-color: $gray-1000 !default;
$body-highlight-bg: $gray-100 !default;

// scss-docs-start theme-text-variables

$primary-text-emphasis:   $blue-700 !default;
$secondary-text-emphasis: $gray-700 !default;
$success-text-emphasis:   $green-700 !default;
$info-text-emphasis:      $cyan-700 !default;
$warning-text-emphasis:   $orange-700 !default;
$danger-text-emphasis:    $red-700 !default;
$light-text-emphasis:     $gray-1100 !default;
$dark-text-emphasis:      $white !default;
// scss-docs-end theme-text-variables

// scss-docs-start theme-bg-subtle-variables
$primary-bg-subtle:       $blue-100 !default;
$secondary-bg-subtle:     $gray-100 !default;
$success-bg-subtle:       $green-100 !default;
$info-bg-subtle:          $cyan-100 !default;
$warning-bg-subtle:       $orange-100 !default;
$danger-bg-subtle:        $red-100 !default;
// $light-bg-subtle:         mix($gray-100, $white) !default;
// $dark-bg-subtle:          $gray-400 !default;
// scss-docs-end theme-bg-subtle-variables

// scss-docs-start theme-border-subtle-variables
$primary-border-subtle:   $blue-300 !default;
$secondary-border-subtle: $gray-300 !default;
$success-border-subtle:   $green-300 !default;
$info-border-subtle:      $cyan-300 !default;
$warning-border-subtle:   $orange-300 !default;
$danger-border-subtle:    $red-300 !default;
$light-border-subtle:     $gray-100 !default;
$dark-border-subtle:      $gray-500 !default;
// scss-docs-end theme-border-subtle-variables


//----------------------- phoenix own colors --------------------

//*-----------------------------------------------
//|   Brand colors
//-----------------------------------------------*/
$linkedin: #0077b5 !default;
$facebook: #3c5a99 !default;
$twitter: #1da1f2 !default;
$google-plus: #dd4b39 !default;
$github: #333333 !default;
$youtube: #ff0001 !default;
$bootstrap: #6f45a9 !default;
$css3: #203ccf !default;
$html5: #bf4b2c !default;
$sass: #c55589 !default;
$gulp: #d54049 !default;
$w3c: #255997 !default;

$brand-colors: (
  'facebook': $facebook,
  'google-plus': $google-plus,
  'twitter': $twitter,
  'linkedin': $linkedin,
  'youtube': $youtube,
  'github': $github,
  'bootstrap': $bootstrap,
  'css3': $css3,
  'html5': $html5,
  'sass': $sass,
  'gulp': $gulp,
  'w3c': $w3c
) !default;

$button-colors: map-remove($theme-colors, 'light', 'dark') !default;

$theme-color-shades: (
  'primary-lighter': $blue-200,
  'primary-light': $blue-300,
  'primary-dark': $blue-600,
  'primary-darker': $blue-700,

  'secondary-lighter': $gray-200,
  'secondary-light': $gray-300,
  'secondary-dark': $gray-1000,
  'secondary-darker': $gray-700,

  'warning-lighter': $orange-200,
  'warning-light': $orange-300,
  'warning-dark': $orange-600,
  'warning-darker': $orange-700,

  'danger-lighter': $red-200,
  'danger-light': $red-300,
  'danger-dark': $red-600,
  'danger-darker': $red-700,

  'success-lighter': $green-200,
  'success-light': $green-300,
  'success-dark': $green-600,
  'success-darker': $green-700,

  'info-lighter': $cyan-200,
  'info-light': $cyan-300,
  'info-dark': $cyan-600,
  'info-darker': $cyan-700,
) !default;


//*-----------------------------------------------
//|   Border
//-----------------------------------------------*/
$border-color: $gray-300 !default;
$border-color-translucent: rgba($border-color, .54) !default;


//*-----------------------------------------------
//|  phoenix specific
//-----------------------------------------------*/
$theme-hover-colors: (
  'primary': $blue-600,
  'secondary': $gray-1000,
  'success': $green-600,
  'info': $cyan-600,
  'warning': $orange-600,
  'danger': $red-600
) !default;


$hover-text-colors: (
  'body-quaternary': var(--#{$prefix}quaternary-color),
  'body-tertiary': var(--#{$prefix}tertiary-color),
  'body-secondary': var(--#{$prefix}secondary-color),
  'body': var(--#{$prefix}body-color),
  'body-highlight': var(--#{$prefix}body-highlight-color),
  'body-emphasis': var(--#{$prefix}emphasis-color),
) !default;

$hover-bg-colors: (
  'body-quaternary': var(--#{$prefix}quaternary-bg),
  'body-tertiary': var(--#{$prefix}tertiary-bg),
  'body-secondary': var(--#{$prefix}secondary-bg),
  'body': var(--#{$prefix}body-bg),
  'body-highlight': var(--#{$prefix}body-highlight-bg),
  'body-emphasis': var(--#{$prefix}emphasis-bg),
) !default;
